import React from 'react';
import * as PropTypes from "prop-types";
import Button from "../../components/Button";
import iconFloodedError from "../../images/icons/form/floodedError.svg";
import iconFloodedSuccess from "../../images/icons/form/floodedSuccess.svg";

const ResultScreen = React.memo(({ buttonTitle, status, title, description, handleButtonClick }) => ( 
    <div className="callBackForm-BlockMessage">
        <div>
            <img src={status === "error" ? iconFloodedError : iconFloodedSuccess} alt="icon" />
        </div>

        {title && <div className="callBackForm-BlockMessage-text">
            {title}
        </div>}

        {description && <div className="callBackForm-BlockMessage-desc">
            {description}
        </div>}

        <div className="callBackForm-BlockMessage-button">
            <Button type="submit" click={handleButtonClick}>
                {buttonTitle}
            </Button>
        </div>
  </div>
));

ResultScreen.propTypes = {
    buttonTitle: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    title: PropTypes.string,
    handleButtonClick: PropTypes.func,
}
 
export default ResultScreen;